<template>
    <div>
        <FG />
    </div>

</template>

<script>
import FG from '@/components/FormGravity.vue'

export default {
    components:{
        FG
    }
}
</script>