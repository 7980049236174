var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"file-uploader-form file-uploader-form--contact d-flex align-items-end flex-wrap"},[_c('div',{staticClass:"d-lg-flex w-100"},[_c('div',{staticClass:"leftside col-12 col-lg-6 align-self-start text-left text-white pt-5"},[_c('h3',[_vm._v(_vm._s(_vm.$store.state.translation.el_contact_form_title))]),_c('p',[_vm._v(_vm._s(_vm.$store.state.translation.el_contact_form_text))])]),_c('div',{staticClass:"col-12 col-lg-6 d-flex flex-column align-items-center screen-height p-0 pb-md-0 pt-5"},[_c('div',{staticClass:"col-12 position-relative p-0"},[_c('div',{staticClass:"form-container w-100"},[(!_vm.formSubmitted || _vm.sendingError)?_c('h3',{staticClass:"text-white p-3 mb-5 text-uppercase"},[_vm._v(_vm._s(_vm.$store.state.translation.dm_form_upload_title))]):_vm._e(),(!_vm.formSubmitted || _vm.sendingError)?_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 pr-md-0"},[_c('validation-provider',{attrs:{"rules":{'required':true,'min': 2},"name":("" + (_vm.$store.state.translation.el_form_firstname))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var classes = ref.classes;
return [_c('div',{staticClass:"isValidate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstName),expression:"form.firstName"}],staticClass:"form-control border-right",attrs:{"type":"text","aria-describedby":"input-live-help input-live-1-feedback","placeholder":("" + (_vm.$store.state.translation.el_form_firstname)),"id":"surname"},domProps:{"value":(_vm.form.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "firstName", $event.target.value)}}})]),_c('portal',{attrs:{"to":"firstName"}},[_c('div',{staticClass:"error"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(Object.entries(failedRules)[0]),expression:"Object.entries(failedRules)[0]"}]},[_vm._v(_vm._s(_vm.getErrorMessage("surname", Object.entries(failedRules)[0])))])])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6 pl-md-0"},[_c('validation-provider',{attrs:{"rules":"required|min:2","name":("" + (_vm.$store.state.translation.el_form_lastname))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var classes = ref.classes;
return [_c('div',{staticClass:"isValidate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastName),expression:"form.lastName"}],staticClass:"form-control",attrs:{"type":"text","aria-describedby":"input-live-help input-live-1-feedback","placeholder":("" + (_vm.$store.state.translation.el_form_lastname)),"id":"lastname"},domProps:{"value":(_vm.form.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastName", $event.target.value)}}})]),_c('portal',{attrs:{"to":"lastName"}},[_c('div',{staticClass:"error"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(Object.entries(failedRules)[0]),expression:"Object.entries(failedRules)[0]"}]},[_vm._v(_vm._s(_vm.getErrorMessage("forname", Object.entries(failedRules)[0])))])])])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 pr-md-0"},[_c('validation-provider',{attrs:{"rules":"required|email","name":("" + (_vm.$store.state.translation.el_form_email))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var classes = ref.classes;
return [_c('div',{staticClass:"isValidate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control border-right",attrs:{"type":"text","placeholder":("" + (_vm.$store.state.translation.el_form_email)),"name":"E-mail cím","id":"email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}})]),_c('portal',{attrs:{"to":"email"}},[_c('div',{staticClass:"error"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(Object.entries(failedRules)[0]),expression:"Object.entries(failedRules)[0]"}]},[_vm._v(_vm._s(_vm.getErrorMessage("email", Object.entries(failedRules)[0])))])])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6 pl-md-0"},[_c('ValidationProvider',{attrs:{"rules":"required","name":("" + (_vm.$store.state.translation.el_form_phone))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var classes = ref.classes;
return [_c('div',{staticClass:"isValidate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"}],staticClass:"form-control",attrs:{"type":"text","placeholder":("" + (_vm.$store.state.translation.el_form_phone)),"name":"Telefonszám","id":"phone"},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phone", $event.target.value)}}})]),_c('portal',{attrs:{"to":"phone"}},[_c('div',{staticClass:"error"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(Object.entries(failedRules)[0]),expression:"Object.entries(failedRules)[0]"}]},[_vm._v(_vm._s(_vm.getErrorMessage("phone", Object.entries(failedRules)[0])))])])])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 pr-md-0"},[_c('validation-provider',{attrs:{"rules":"required","name":("" + (_vm.$store.state.translation.el_form_street))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var classes = ref.classes;
return [_c('div',{staticClass:"isValidate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.street),expression:"form.street"}],staticClass:"form-control border-right",attrs:{"type":"text","placeholder":("" + (_vm.$store.state.translation.el_form_street)),"name":"Street and nr.","id":"street"},domProps:{"value":(_vm.form.street)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "street", $event.target.value)}}})]),_c('portal',{attrs:{"to":"street"}},[_c('div',{staticClass:"error"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(Object.entries(failedRules)[0]),expression:"Object.entries(failedRules)[0]"}]},[_vm._v(_vm._s(_vm.getErrorMessage("street", Object.entries(failedRules)[0])))])])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6 pl-md-0"},[_c('ValidationProvider',{attrs:{"rules":"required","name":("" + (_vm.$store.state.translation.el_form_city))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var classes = ref.classes;
return [_c('div',{staticClass:"isValidate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.city),expression:"form.city"}],staticClass:"form-control",attrs:{"type":"text","name":"City","id":"city","placeholder":("" + (_vm.$store.state.translation.el_form_city))},domProps:{"value":(_vm.form.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "city", $event.target.value)}}})]),_c('portal',{attrs:{"to":"city"}},[_c('div',{staticClass:"error"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(Object.entries(failedRules)[0]),expression:"Object.entries(failedRules)[0]"}]},[_vm._v(_vm._s(_vm.getErrorMessage("city", Object.entries(failedRules)[0])))])])])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 pr-md-0"},[_c('validation-provider',{attrs:{"rules":"required","name":("" + (_vm.$store.state.translation.el_form_postcode))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var classes = ref.classes;
return [_c('div',{staticClass:"isValidate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.postcode),expression:"form.postcode"}],staticClass:"form-control border-right",attrs:{"type":"text","placeholder":("" + (_vm.$store.state.translation.el_form_postcode)),"name":"Postcode*","id":"postcode"},domProps:{"value":(_vm.form.postcode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "postcode", $event.target.value)}}})]),_c('portal',{attrs:{"to":"postcode"}},[_c('div',{staticClass:"error"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(Object.entries(failedRules)[0]),expression:"Object.entries(failedRules)[0]"}]},[_vm._v(_vm._s(_vm.getErrorMessage("postcode", Object.entries(failedRules)[0])))])])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6 pl-md-0"},[_c('ValidationProvider',{attrs:{"rules":"required","name":("" + (_vm.$store.state.translation.el_form_country))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var classes = ref.classes;
return [_c('div',{staticClass:"isValidate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.country),expression:"form.country"}],staticClass:"form-control",attrs:{"type":"text","name":"country","placeholder":("" + (_vm.$store.state.translation.el_form_country)),"id":"country"},domProps:{"value":(_vm.form.country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "country", $event.target.value)}}})]),_c('portal',{attrs:{"to":"country"}},[_c('div',{staticClass:"error"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(Object.entries(failedRules)[0]),expression:"Object.entries(failedRules)[0]"}]},[_vm._v(_vm._s(_vm.getErrorMessage("country", Object.entries(failedRules)[0])))])])])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"rules":"required|min:3","name":("" + (_vm.$store.state.translation.el_form_message))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var classes = ref.classes;
return [_c('div',{staticClass:"isValidate",class:classes},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],ref:"msgTextarea",staticClass:"form-control--message form-control",attrs:{"name":"message","id":"message","placeholder":("" + (_vm.$store.state.translation.el_form_message))},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "message", $event.target.value)}}})]),_c('portal',{attrs:{"to":"message"}},[_c('div',{staticClass:"error"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(Object.entries(failedRules)[0]),expression:"Object.entries(failedRules)[0]"}]},[_vm._v(_vm._s(_vm.getErrorMessage("message", Object.entries(failedRules)[0])))])])])]}}],null,true)})],1)]),_c('router-link',{staticClass:"terms pt-3 px-3 d-block",attrs:{"to":{name: ("PrivacyPolicy_" + _vm.selectedLangCode)}},domProps:{"innerHTML":_vm._s(_vm.$store.state.translation.dm_lawtext)}}),(_vm.sendingError)?_c('div',{staticClass:"error error--file justify-content-center"},[_c('span',[_vm._v(_vm._s(_vm.getErrorMessage("errorApi", Object.entries(_vm.apiErrorText)[0])))])]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{attrs:{"type":"submit","variant":"btn btn--submit form-btn"}},[_vm._v(_vm._s(_vm.$store.state.translation.el_form_upload_send)+" "),_c('img',{staticClass:"img-fluid mb-1",attrs:{"src":require("../assets/icons/arrow-send.svg")}})])],1)],1)]}}],null,false,1117262602)}):_vm._e(),(_vm.formSubmitted && !_vm.sendingError)?_c('div',{staticClass:"d-flex justify-content-center align-items-center form-success text-white"},[_c('h1',{staticClass:"text-center text-uppercase"},[_vm._v(_vm._s(_vm.$store.state.translation.el_form_success_title))])]):_vm._e()],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }